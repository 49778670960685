import {
   type AnalyticsItemsEvent,
   type CurrencyCode,
   convertCartLineItemsToAnalyticsItem,
   toUSD,
   trackInPiwik,
} from '@ifixit/analytics';
import { getVariantIdFromVariantURI } from '@ifixit/helpers';
import { AddToCartInput } from '../hooks/cart/use-add-to-cart';

export type AddToCartEventType =
   | 'Cart Page Cross Sell'
   | 'Cart Page Merch'
   | 'Cart Drawer Cross Sell'
   | 'Cart Drawer Merch'
   | 'Frequently Bought Together'
   | 'Guide Products';

export function trackPiwikShopifyAddToCart(
   { lines, analytics }: AddToCartInput,
   { eventType }: { eventType?: AddToCartEventType }
) {
   const itemcodeList = `${lines.map(line => line.itemcode).join(', ')}`;
   switch (analytics.type) {
      case 'product': {
         const event = `Add to Cart` + (eventType ? ` - ${eventType}` : '');
         const itemcodes =
            lines.length === 1 && lines[0].itemcode === analytics.currentItemCode
               ? analytics.currentItemCode
               : `${analytics.currentItemCode}/${itemcodeList}`;
         const variantid = getVariantIdFromVariantURI(analytics.variantid);
         const totalPriceUSD = lines.reduce((sum, line) => {
            const lineUSD = toUSD(
               Number(line.price.amount),
               line.price.currencyCode as CurrencyCode
            );
            const linePrice = line.quantity * lineUSD;
            return sum + linePrice;
         }, 0);
         trackInPiwik({
            eventCategory: event,
            eventAction: `${event} - ${itemcodes}`,
            eventName: `${analytics.handle} - ${variantid} - ${itemcodes}`,
            eventValue: totalPriceUSD,
         });
         break;
      }
      case 'buy-it-again': {
         const quantityList = `${lines.map(line => line.quantity).join(', ')}`;
         trackInPiwik({
            eventCategory: 'Add to Cart - Buy it Again',
            eventAction: itemcodeList,
            eventName: quantityList,
         });
         break;
      }
      case 'bundle': {
         const event = `Add to Cart` + (eventType ? ` - ${eventType}` : '');
         const itemcodes = `${analytics.currentItemCode}/${analytics.itemCodes.join(', ')}`;
         const variantid = getVariantIdFromVariantURI(analytics.variantid);
         trackInPiwik({
            eventCategory: event,
            eventAction: `${event} - ${itemcodes}`,
            eventName: `${analytics.handle} - ${variantid} - ${itemcodes}`,
         });
         break;
      }
   }
}

export function convertAddToCartInputToAnalyticsItemEvent(
   input: AddToCartInput
): AnalyticsItemsEvent {
   return {
      items: convertCartLineItemsToAnalyticsItem(input.lines),
      value: input.lines.reduce((acc, item) => acc + Number(item.price.amount), 0),
      currency: input.lines[0]?.price.currencyCode,
   };
}
