import { type ProductVariantID, getVariantIdFromVariantURI } from '@ifixit/helpers';
import { captureException, SentryError } from '@ifixit/sentry';
import { GetCartQuery } from '@ifixit/shopify-storefront-client';
import { z } from 'zod';
import type { Cart, CartLineItem } from '../types';

type QueryCart = NonNullable<GetCartQuery['cart']>;
type QueryLineItem = QueryCart['lines']['edges'][0]['node'];

type BuildIfixitCartProps = {
   cart: QueryCart | null;
   fallbackCurrencyCode: string;
};
export function buildIfixitCart({ cart, fallbackCurrencyCode }: BuildIfixitCartProps): Cart {
   if (cart == null) {
      return emptyCart(fallbackCurrencyCode);
   }
   const subtotalAmount = cart.cost.subtotalAmount;
   const currencyCode =
      subtotalAmount.currencyCode === 'XXX' ? fallbackCurrencyCode : subtotalAmount.currencyCode;
   const compareAtPriceAmount = cart.lines.edges.reduce((sum, { node: lineItem }) => {
      const amountPerQuantity =
         lineItem.cost.compareAtAmountPerQuantity ?? lineItem.merchandise.price;
      return sum + Number.parseFloat(amountPerQuantity.amount) * lineItem.quantity;
   }, 0);
   const discountAmount = compareAtPriceAmount - Number.parseFloat(subtotalAmount.amount);
   const compareAtPrice = {
      amount: compareAtPriceAmount.toFixed(2),
      currencyCode,
   };
   const discount = {
      amount: discountAmount,
      currencyCode,
   };
   return {
      hasItemsInCart: cart.totalQuantity > 0,
      isEmpty: cart.totalQuantity === 0,
      lineItems: cart.lines.edges.map(({ node }) => buildIfixitLineItem(node)),
      totals: {
         discount,
         itemsCount: cart.totalQuantity,
         price: {
            amount: subtotalAmount.amount,
            currencyCode,
         },
         compareAtPrice,
      },
      checkoutUrl: cart.checkoutUrl,
      shopifyCartId: cart.id,
   };
}

function buildIfixitLineItem(lineItem: QueryLineItem): CartLineItem {
   const variant = lineItem.merchandise;
   if (!variant.sku) {
      throw new SentryError('Shopify line item is missing SKU', { extra: { lineItem } });
   }
   const globalVariantId = variant.id as ProductVariantID;
   const variantid = getVariantIdFromVariantURI(globalVariantId);
   const isPreorder = variant.product.tags.includes('Preorder');
   const parsedAnalytics = parseAnalyticsDimensionsMetafield(variant.metafieldAnalyticsDimensions);
   return {
      shopifyLineId: lineItem.id,
      itemcode: variant.sku,
      variantTitle: variant.title,
      shopifyVariantId: globalVariantId,
      name: variant.product.title,
      internalDisplayName: variant.metafieldInternalDisplayName?.value,
      imageSrc: variant.image?.url,
      quantity: lineItem.quantity,
      quantityAvailable: (isPreorder ? null : variant.quantityAvailable) ?? undefined,
      price: lineItem.cost.amountPerQuantity,
      compareAtPrice: lineItem.cost.compareAtAmountPerQuantity ?? lineItem.merchandise.price,
      categories: getCategoriesFromAnalyticsDimensions(parsedAnalytics),
      fulfiller: parsedAnalytics?.fulfiller,
      url: `/products/${variant.product.handle}?variant=${variantid}`,
   };
}

const AnalyticsDimensionsSchema = z.object({
   is_tool: z.boolean(),
   item_category: z.string(),
   part_subcategory: z.string(),
   fulfiller: z.optional(z.string()),
});

type AnalyticsDimensions = {
   is_tool: boolean;
   item_category: string;
   part_subcategory: string;
   fulfiller?: string;
};

export function parseAnalyticsDimensionsMetafield(
   metafield: { value: string } | null | undefined
): AnalyticsDimensions | null {
   if (metafield == null || !metafield.value) {
      return null;
   }
   let asObj = {};
   try {
      asObj = JSON.parse(metafield.value);
   } catch (error) {
      console.error('Failed to parse analytics dimensions metafield', { error });
      captureException(
         new SentryError('Failed to parse analytics dimensions metafield', {
            contexts: {
               'Error Data': {
                  'metafield': metafield,
                  'JSONParseError': error,
               },
            },
         })
      );
      return null;
   }
   try {
      const parsed = AnalyticsDimensionsSchema.parse(asObj);
      return parsed;
   } catch (error) {
      console.error('Failed to parse analytics dimensions metafield', { error });
      captureException(
         new SentryError('Failed to parse analytics dimensions metafield', {
            contexts: {
               'Error Data': {
                  'metafield': metafield,
                  'Parsed Metafield': asObj,
                  'ZodError': error,
               },
            },
         })
      );
      return null;
   }
}

export function getCategoriesFromAnalyticsDimensions(
   dimensions: AnalyticsDimensions | null
): string[] | null {
   return dimensions
      ? [
           dimensions.is_tool ? 'Tool' : 'Part',
           dimensions.item_category,
           dimensions.part_subcategory,
        ]
      : null;
}

function emptyCart(fallbackCurrencyCode: string): Cart {
   return {
      hasItemsInCart: false,
      isEmpty: true,
      lineItems: [],
      totals: {
         discount: { amount: 0, currencyCode: fallbackCurrencyCode },
         itemsCount: 0,
         price: { amount: '0', currencyCode: fallbackCurrencyCode },
         compareAtPrice: { amount: '0', currencyCode: fallbackCurrencyCode },
      },
   };
}
